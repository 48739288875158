// Generated by Framer (33d5032)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/GOdR0HeFT";

const cycleOrder = ["Os7Kb6RuU", "OK4M4_fZX"];

const serializationHash = "framer-WJvHL"

const variantClassNames = {OK4M4_fZX: "framer-v-ahy178", Os7Kb6RuU: "framer-v-1knddr3"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "Os7Kb6RuU", "Variant 2": "OK4M4_fZX"}

const getProps = ({activate, deactivate, height, id, text, width, ...props}) => { return {...props, DRzBTZ6xd: activate ?? props.DRzBTZ6xd, QLyhrRFXW: deactivate ?? props.QLyhrRFXW, S4JkbNlQN: text ?? props.S4JkbNlQN ?? "Development", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Os7Kb6RuU"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;text?: string;activate?: any;deactivate?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, S4JkbNlQN, DRzBTZ6xd, QLyhrRFXW, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Os7Kb6RuU", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTapb54a7d = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (DRzBTZ6xd) {
const res = await DRzBTZ6xd(...args);
if (res === false) return false;
}
})

const onTap1l7nltg = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (QLyhrRFXW) {
const res = await QLyhrRFXW(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1knddr3", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"Os7Kb6RuU"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={onTapb54a7d} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 16, borderBottomRightRadius: 16, borderTopLeftRadius: 16, borderTopRightRadius: 16, ...style}} variants={{OK4M4_fZX: {backgroundColor: "var(--token-368a16a6-498a-4191-9ddc-80716de2a65f, rgb(53, 58, 119))"}}} {...addPropertyOverrides({OK4M4_fZX: {"data-framer-name": "Variant 2", onTap: onTap1l7nltg}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-900t44"} data-styles-preset={"GOdR0HeFT"} style={{"--framer-text-color": "var(--extracted-r6o4lv, var(--token-818a283b-450e-452c-a8af-6b81d42f4181, rgb(0, 0, 0)))"}}>Development</motion.p></React.Fragment>} className={"framer-ltf9fb"} data-framer-name={"Set 2"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"T_NGgFz9U"} style={{"--extracted-r6o4lv": "var(--token-818a283b-450e-452c-a8af-6b81d42f4181, rgb(0, 0, 0))"}} text={S4JkbNlQN} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({OK4M4_fZX: {children: <React.Fragment><motion.p className={"framer-styles-preset-900t44"} data-styles-preset={"GOdR0HeFT"}>Development</motion.p></React.Fragment>}}, baseVariant, gestureVariant)}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-WJvHL.framer-1xz79vi, .framer-WJvHL .framer-1xz79vi { display: block; }", ".framer-WJvHL.framer-1knddr3 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 12px 24px 12px 24px; position: relative; width: min-content; }", ".framer-WJvHL .framer-ltf9fb { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-WJvHL.framer-1knddr3 { gap: 0px; } .framer-WJvHL.framer-1knddr3 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-WJvHL.framer-1knddr3 > :first-child { margin-left: 0px; } .framer-WJvHL.framer-1knddr3 > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 180
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"OK4M4_fZX":{"layout":["auto","auto"]}}}
 * @framerVariables {"S4JkbNlQN":"text","DRzBTZ6xd":"activate","QLyhrRFXW":"deactivate"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerICidaELal: React.ComponentType<Props> = withCSS(Component, css, "framer-WJvHL") as typeof Component;
export default FramerICidaELal;

FramerICidaELal.displayName = "AltCv / Tab";

FramerICidaELal.defaultProps = {height: 50, width: 180};

addPropertyControls(FramerICidaELal, {variant: {options: ["Os7Kb6RuU", "OK4M4_fZX"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}, S4JkbNlQN: {defaultValue: "Development", displayTextArea: false, title: "Text", type: ControlType.String}, DRzBTZ6xd: {title: "Activate", type: ControlType.EventHandler}, QLyhrRFXW: {title: "Deactivate", type: ControlType.EventHandler}} as any)

addFonts(FramerICidaELal, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-ext-CFTLRB35.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://app.framerstatic.com/Inter-Regular.cyrillic-KKLZBALH.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://app.framerstatic.com/Inter-Regular.greek-ext-ULEBLIFV.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://app.framerstatic.com/Inter-Regular.greek-IRHSNFQB.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://app.framerstatic.com/Inter-Regular.latin-ext-VZDUGU3Q.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://app.framerstatic.com/Inter-Regular.latin-JLQMKCHE.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://app.framerstatic.com/Inter-Regular.vietnamese-QK7VSWXK.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts)], {supportsExplicitInterCodegen: true})